import { FC, ReactNode } from "react";

import { Button } from "@components/Button";
import StepIndicator from "@components/StepIndicator/StepIndicator";

interface Props {
  currentStepNumber: number;
  totalStepsNumber: number;
  title: string;
  isNextButtonLoading?: boolean;
  onNextClick?: () => void;
  nextButton?: ReactNode;
}

const GoFlowMobileHeader: FC<Props> = ({
  currentStepNumber,
  totalStepsNumber,
  title,
  isNextButtonLoading,
  onNextClick,
  nextButton,
}) => {
  return (
    <div className="flex items-center justify-between bg-black-ink/10 backdrop-blur-md fixed top-0 w-full md:hidden p-6 pt-8 z-10">
      <div>
        <StepIndicator
          className=""
          currentStep={currentStepNumber}
          totalSteps={totalStepsNumber}
        />
        <h2 className="mt-4 text-2xl font-normal sm:text-3xl sm:leading-10 text-white">
          {title}
        </h2>
        <div className="text-grey-500 -mt-1">
          Step {currentStepNumber} out of {totalStepsNumber}
        </div>
      </div>
      {nextButton && <div>{nextButton}</div>}
      {onNextClick && (
        <div>
          <Button
            className=""
            variant="primary-theme"
            onClick={onNextClick}
            isLoading={isNextButtonLoading}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoFlowMobileHeader;
