import { FC } from "react";
import classNames from "classnames";

interface StepIndicatorProps {
  className?: string;
  currentStep: number;
  totalSteps: number;
}

const StepIndicator: FC<StepIndicatorProps> = ({
  className,
  currentStep,
  totalSteps,
}) => {
  return (
    <div className={classNames("h-1.5 w-28 flex gap-x-1", className)}>
      {[...Array(totalSteps).keys()].map((i) => (
        <div
          key={i}
          className={classNames(
            "flex-1 rounded-sm",
            i < currentStep ? "bg-white" : "bg-grey-500"
          )}
        />
      ))}
    </div>
  );
};

export default StepIndicator;
