import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { updateUserOnboardingValues } from "api-services/definitions/user";
import { api } from "api-services/endpoints";
import axios from "axios";
import { Button } from "components/Button";
import TextField from "components/Client/TextField";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import analytics from "@lib/analytics";

import { ErrorMessage } from "@components/ErrorMessage";
import GoogleAccountIcon from "@components/Icons/GoogleAccountIcon";
import PasswordTextfield from "@components/PasswordTextfield";

import GoFlowMobileHeader from "./GoFlowMobileHeader";

type FormDataType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export const useAuthErrorState: () => [
  string,
  (value: string) => void,
] = () => {
  const router = useRouter();

  const [errorMessage, setErrorMessage] = useState<string>(
    router.query?.auth_error as string
  );

  useEffect(() => {
    const error = router.query?.auth_error as string;
    if (error) setErrorMessage(error);
  }, [router.query?.auth_error]);

  return [errorMessage, setErrorMessage];
};

const SignUpUnifiedOnboardingForm: FC<{
  save: (data: { password: string; email: string }) => Promise<string>;
  prefilledEmail?: string;
  onSubmitGoogle?: () => Promise<void>;
  emailDisabled?: boolean;
  onSubmitButtonText: string;
  title: string;
  currentStepNumber: number;
  totalStepsNumber: number;
}> = ({
  save,
  prefilledEmail,
  onSubmitGoogle,
  emailDisabled,
  onSubmitButtonText,
  title,
  currentStepNumber,
  totalStepsNumber,
}) => {
  const router = useRouter();
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useAuthErrorState();

  const formMethods = useForm<FormDataType>({
    firstName: "",
    lastName: "",
    email: prefilledEmail || "",
    password: "",
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = formMethods;

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
  }: FormDataType) => {
    try {
      analytics.track({
        event: "sign_up_click",
        properties: { method: "web", kind: "password" },
      });

      const aid = await save({ email, password, firstName, lastName });
      await auth.signin(email, password);
      await api(updateUserOnboardingValues, {
        path: { userId: aid },
        body: {
          completedGoFlowProfileForm: true,
          completedGoFlowCollectionForm: null,
          completedGoFlowTimeToShine: null,
          seenVideos: [],
        },
      });
      await axios.post(`/api/v1/users/${aid}/update-profile`, {
        firstName,
        lastName,
        fromGoFlow: true,
      });
      analytics.track({
        event: "sign_up",
        properties: { method: "web", kind: "password" },
      });
      router.replace({
        query: { ...router.query, step: 2 },
      });
    } catch (e) {
      const message =
        e.response.data?.error?.message || "Something went wrong!";

      setErrorMessage(message);

      analytics.track({
        event: "sign_up_error",
        properties: { method: "web", kind: "password", error: message },
      });
    }
  };

  const textFieldProps = {
    containerClassName: "my-0",
    inputClassName: "mt-0",
  };

  return (
    <>
      <GoFlowMobileHeader
        title={title}
        currentStepNumber={currentStepNumber}
        totalStepsNumber={totalStepsNumber}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full max-w-sm space-y-6"
      >
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <TextField
                autoFocus
                errors={errors}
                register={register}
                id="firstName"
                label="First name"
                required
                placeholder="Enter first name"
                {...textFieldProps}
              />
            </div>
            <div className="flex-1">
              <TextField
                errors={errors}
                register={register}
                id="lastName"
                label="Last name"
                required
                placeholder="Enter last name"
                {...textFieldProps}
              />
            </div>
          </div>
          <TextField
            label="Email"
            id="email"
            disabled={emailDisabled}
            errorMessage="Email is required"
            register={register}
            errors={errors}
            required
            isEmailErrorMessage="Email should be a valid email address"
            isEmail
            placeholder="Enter email"
          />
          <div className="space-y-1">
            <PasswordTextfield
              errors={errors}
              register={register}
              placeholder="Choose a password"
            />
          </div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
        <div className="space-y-2">
          <Button
            className="w-full"
            type="submit"
            variant="primary-theme"
            isLoading={isSubmitting}
          >
            {onSubmitButtonText}
          </Button>
          {onSubmitGoogle && (
            <>
              <div className="text-grey-500 text-center">or</div>
              <Button
                className="w-full"
                type="button"
                icon={<GoogleAccountIcon className="w-6 h-6" />}
                variant="white"
                onClick={onSubmitGoogle}
              >
                Continue with Google
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default SignUpUnifiedOnboardingForm;
