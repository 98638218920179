import { FC, useState } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import TextField from "components/Client/TextField";
import ViewOffIcon from "components/Icons/ViewOffIcon";
import ViewOnIcon from "components/Icons/ViewOnIcon";

interface PasswordTextfieldProps {
  register: UseFormRegister<any>;
  errors: Partial<FieldErrorsImpl>;
  placeholder?: string;
}
const PasswordTextfield: FC<PasswordTextfieldProps> = ({
  register,
  errors,
  placeholder = "Enter your password",
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const showPasswordOption = (
    <div className="text-grey-500 mt-1 cursor-pointer">
      {showPassword ? (
        <div onClick={() => setShowPassword(false)}>
          <ViewOffIcon />
        </div>
      ) : (
        <div onClick={() => setShowPassword(true)}>
          <ViewOnIcon />
        </div>
      )}
    </div>
  );
  return (
    <TextField
      autoFocus
      label="Password"
      id="password"
      errorMessage="Password is required"
      register={register}
      containerClassName="relative"
      errors={errors}
      required
      type={showPassword ? "text" : "password"}
      placeholder={placeholder}
      rightComponent={showPasswordOption}
    />
  );
};

export default PasswordTextfield;
